@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap);
.preloader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgb(49, 49, 177);
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 1;
    transition: 1s opacity;
  }
  .preloader.loaded {
    opacity: 0;
    visibility: hidden;
  }
  .preloader .spinner_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 33333;
  }
  .preloader .spinner {
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.8);
    animation: sk-rotateplane 1.2s infinite ease-in-out;
  }
  @keyframes sk-rotateplane {
    0% {
      transform: perspective(120px) rotateX(0deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
      transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
  }
  
#countdown {
    width: 250px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    display: inline-block;
    text-align: center;
    margin: auto;
  }
  #countdown .box {
    padding: 10px;
    border-right: solid 1px rgba(255, 255, 255, 0.2);
  }
  #countdown .col-4:last-child .box {
    border-right-color: transparent;
  }
  #countdown .box p {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }
  #countdown .box .text {
    font-size: 12px;
    font-family: sans-serif;
  }
  
  @media (min-width: 768px) {
    .container {
      width: 1100px;
    }
    h1 {
      font-size: 58px;
    }
    #countdown {
      width: 350px;
      padding: 20px;
    }
  }
  
body {
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  background-image: url(https://images.unsplash.com/photo-1508389377389-b8221c0bcc9e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80);
  background-position: top top;
  background-size: cover;
  margin: 0;
}

.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: rgb(49, 49, 177);
  background: linear-gradient(
    0deg,
    rgba(49, 49, 177, 1) 0%,
    rgba(49, 49, 177, 1) 50%,
    rgba(49, 49, 177, 0.6) 100%
  );
}

.container {
  width: 100%;
  margin: 15% auto;
}
.col-4 {
  width: 25%;
  float: left;
}

h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
}

@media (min-width: 768px) {
  .container {
    width: 1100px;
  }
  h1 {
    font-size: 58px;
  }
}

